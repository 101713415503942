import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {SERVER_API} from '../API'

Vue.use(Vuex)
axios.defaults.baseURL = SERVER_API;
function isBase64(str){
  try{
      return btoa(atob(str)).replace(/=/g,"") == str
  }
  catch(err){
      return false
  }
}
var valid = localStorage.getItem('access_token') == null ? null : isBase64(localStorage.getItem('access_token').split('.')[1])
var splitToken =  valid ? JSON.parse(atob(localStorage.getItem('access_token').split('.')[1])).sub : false;


export const store = new Vuex.Store({

    state: {
        token: localStorage.getItem('access_token') || null,
        //unit: localStorage.getItem('un') || null,
        role: localStorage.getItem('rl') || null,
        uName: localStorage.getItem('uName') || null,
        userId: splitToken

    },
    // Gater
    getters: {
        loggedIn(state) {
          return state.token !== null
        },
    },
    mutations: {
        retrieveToken(state, token) {
          state.token = token
        },
        destroyToken(state) {
          state.token = null;
          state.role = null;
          //state.unit = null;
        },
        roleandunitset(state, response){
          state.role = response.role;
          //state.unit = response.unit;
        }
    },
    actions: {
      /*roleandunitset(context){
        axios.get('/user/info')
        .then(response => {
            localStorage.setItem('un',response.data.unit);
            localStorage.setItem('rl',response.data.role);
            context.commit('roleandunitset',response.data);
        })
      },*/
      // Destroy Token on logout
      destroyToken(context) {
        if (context.getters.loggedIn) {
          return new Promise((resolve, reject) => {
            axios.post('/user/logout')
              .then(response => {
                localStorage.removeItem('access_token')
                //localStorage.removeItem('un')
                localStorage.removeItem('rl')
                localStorage.removeItem('uName')
                context.commit('destroyToken')
                resolve(response)
              })
              .catch(error => {
                localStorage.removeItem('access_token')
               // localStorage.removeItem('un')
                localStorage.removeItem('rl')
                localStorage.removeItem('uName')
                context.commit('destroyToken')
                reject(error)
              })
          })
        }
      },
      // Fetch Token on Login
      retrieveToken(context, credentials) {
        return new Promise((resolve, reject) => {
          axios.post('/user/login', {
            username: credentials.username,
            password: credentials.password,
          })
          .then(response => {
            if(response.status == 200){
              const token = response.data.data.access_token
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
              localStorage.setItem('access_token', token);
              localStorage.setItem('rl',response.data.loginManage.role);
              localStorage.setItem('uName',response.data.loginManage.name);
              context.commit('retrieveToken', token);
              resolve(response)
            }else{
              response.error;
            }
             
          })
          .catch(error => {
           reject(error)
          })
        })
      }
    }
});